.sidebar {
  width: 250px; /* Define a largura do Sidebar */
  background-color: #2c3e50; /* Cor azul do título do gráfico */
  color: white; /* Cor do texto */
  height: 100vh; /* Ocupa 100% da altura da janela */
  display: flex;
  flex-direction: column;
  position: fixed; /* Mantém o Sidebar fixo */
  top: 0;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra para destacar */
  z-index: 1000; /* Garante que o Sidebar fique acima de outros elementos */
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px; /* Espaçamento interno */
}

.sidebar-header .sidebar-title {
  font-size: 18px; /* Tamanho da fonte */
  font-weight: bold; /* Texto em negrito */
  margin-left: 10px; /* Espaçamento entre o ícone e o texto */
  color: white; /* Garante que o título fique branco */
}

.sidebar-menu {
  list-style: none; /* Remove os marcadores */
  padding: 0;
  margin: 0;
}

.sidebar-menu .sidebar-dropdown {
  padding: 10px 15px; /* Espaçamento interno */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transição suave */
  color: white; /* Cor do texto */
}

.sidebar-menu .sidebar-dropdown.active,
.sidebar-menu .sidebar-dropdown:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Fundo mais claro ao passar o mouse */
}

.sidebar-menu .sidebar-icon {
  margin-right: 15px; /* Espaçamento adicional entre ícone e texto */
  font-size: 18px; /* Tamanho do ícone */
}

.sidebar-menu .sidebar-submenu {
  list-style: none; /* Remove os marcadores */
  padding-left: 20px; /* Indenta os submenus */
  margin: 10px 0;
}

.sidebar-menu .sidebar-submenu li {
  padding: 5px 0;
  cursor: pointer;
  transition: color 0.3s ease; /* Transição suave para o hover */
  color: white; /* Cor do texto */
}

.sidebar-menu .sidebar-submenu li:hover {
  color: #1abc9c; /* Cor verde para destaque ao passar o mouse */
}

.sidebar-footer {
  margin-top: auto; /* Posiciona o rodapé no final do Sidebar */
  padding: 15px;
  text-align: center;
  font-size: 14px; /* Tamanho menor para o rodapé */
  background-color: #2c3e50; /* Um azul mais escuro para contraste */
  color: rgba(255, 255, 255, 0.7); /* Texto mais claro */
}

.sidebar-footer a {
  color: #1abc9c; /* Cor verde para links */
  text-decoration: none;
}

.sidebar-footer a:hover {
  text-decoration: underline; /* Sublinhar ao passar o mouse */
}
