@import '~@fortawesome/fontawesome-free/css/all.css';

/* Estilos para o Sidebar */
.sidebar {
    width: 250px;
    background-color: #2c3e50; /* Tom petróleo */
    color: white;
    padding: 15px;
    height: 100vh;
    box-sizing: border-box;
}

.sidebar h2 {
    margin-top: 0;
    font-size: 1.2em;
    color: #ffffff; /* Cor branca para contraste */
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: white; /* Texto branco */
}

.sidebar li:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Fundo mais claro ao passar o mouse */
}

.sidebar li.active {
    background-color: rgba(255, 255, 255, 0.2); /* Fundo mais claro para item ativo */
    color: #ffffff; /* Texto branco para contraste */
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Estilos para o layout do Dashboard */
.dashboard {
    display: flex;
}

.content {
    flex: 1;
    padding: 20px;
    background-color: #2c3e50; /* Tom petróleo */
    color: white; /* Texto branco */
    box-sizing: border-box;
}

.content-wrapper {
    margin-left: 250px; /* Mesma largura do Sidebar */
    padding: 20px;
    min-height: 100vh; /* Garante que o conteúdo tenha 100% da altura */
    background-color: #f8f9fa; /* Fundo claro para contraste com o petróleo */
    box-sizing: border-box;
}

div {
    margin-top: 20px;
}

label {
    margin-right: 10px;
}

select {
    margin-right: 20px;
    padding: 5px;
}

.top-menu {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 1000;
}

.fas.fa-bars {
    cursor: pointer;
}

.card-header {
    background-color: #2c3e50; /* Fundo no tom petróleo */
    color: white; /* Texto branco para contraste */
    font-weight: bold;
    font-size: 16px; /* Ajuste o tamanho da fonte conforme necessário */
    border-bottom: none; /* Remove borda, se presente */
}

.btn-primary {
    background-color: #2c3e50; /* Fundo no tom petróleo */
    border-color: #2c3e50; /* Borda no tom petróleo */
    color: white; /* Texto branco */
}

.btn-primary:hover {
    background-color: rgba(44, 62, 80, 0.8); /* Fundo mais claro ao passar o mouse */
    border-color: rgba(44, 62, 80, 0.8); /* Borda mais clara ao passar o mouse */
}


body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

